<template lang="pug">
  page-layout.policy.relative.px-12.text-13.md-text-15(v-if="doc")
    //- header
    h1.text-center.mb-em.font-medium(slot="header") {{ doc.title }}
    //- body
    section.sm-w-14x18.lg-w-10x18.mx-auto.px-12.child-p-mb-em.leading-wide(v-html="doc.body", :class="{'policy--two-cols': $route.meta.handle === 'privacyPolicy'}")

    shop-footer-links.absolute.bottom-0.left-0.w-full
</template>

<script>
import PageLayout from '@/components/PageLayout'
import ShopFooterLinks from '@/components/ShopFooterLinks'
export default {
  name: 'Policy',
  components: { PageLayout, ShopFooterLinks },
  data () {
    return {
      doc: undefined
    }
  },
  async created () {
    this.doc = await this.$store.dispatch('shop/getShopPolicy', this.$route.meta.handle)
  }
}
</script>

<style lang="postcss">
@import '../style/_settings.css';

.policy {
  & b, & strong {
    @apply font-medium;
  }
}

/* privacy policy / two column styling... */
.policy--two-cols {
  /* ... */
  & h1, & h2, & h3, & h4, & h5, & h6 {
    @apply font-medium text-center mb-em;
  }
}

@media (--bkpt-sm) {
  .policy--two-cols {
    columns: 2;
    column-gap: 2.4rem;
    word-break: break-all;
  }
}

</style>
